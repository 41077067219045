/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { navigate } from 'gatsby-link';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { myStyles } from './styles';
import Map from '../static-map/map';
import '../../styles.scss';
import { LeadFormContext, LeadFormContextDispatch } from '../../utils/leadForm-context';
import { themeStyles } from '../../styles';
import ThemeContext from '../../utils/theme-context';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export default function SellLocation({ data, context }) {
  const classes = myStyles();
  const partnerTheme = useContext(ThemeContext);
  const globalThemeCss = themeStyles(partnerTheme);
  const dispatch = useContext(LeadFormContextDispatch);
  const leadFormStore = useContext(LeadFormContext);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [lead, setLead] = useState('');
  const [sellLocation, setSellLocation] = useState('');
  const [apt, setAptValue] = useState('');
  const [location, setLocation] = useState('');
  const compBasicModalLeadForm = useState(context.appData.compBasicModalLeadForm);

  useEffect(() => {
    if (performance.getEntriesByType('navigation')[0].type === 'navigate') {
      switch (context.flowOrder) {
        case 'Selling Only':
          navigate('/customer-info');
          break;
        case 'Buying Only':
          navigate('/customer-info');
          break;
        case 'Buying, Selling':
          navigate('/service-selection');
          break;
        case 'Selling, Buying':
          navigate('/service-selection');
          break;
        default:
          navigate('/');
      }
    }
    const getLocation = JSON.parse(localStorage.getItem('sellLocation'));
    const getSellApt = JSON.parse(localStorage.getItem('sellApt'));
    setSellLocation(getLocation);
    const getLead = JSON.parse(localStorage.getItem('lead'));
    setLead(getLead || {});
    setNextDisabled(!getLead?.sellingLocation?.selectedOption);
    if (!getLocation || getLocation !== getLead?.sellingLocation?.selectedOption) {
      setNextDisabled(true);
    }
    if (getLead && getLead.sellingLocation && getLead.sellingLocation.selectedOption) {
      setLocation(getLead.sellingLocation.selectedOption);
    }
    setAptValue(getSellApt || '');
  }, []);

  useEffect(() => {
    localStorage.setItem('sellApt', JSON.stringify(apt));
  }, [apt]);

  const onAptChange = (e) => {
    setAptValue(e?.target?.value || '');
  };

  const setPlace = (location) => {
    if (typeof location === 'object' && Object.keys(location).length > 0 && location.address !== ''
    && location.city !== '' && location.selectedOption !== '' && location.state !== '' && location.zip !== '') {
      dispatch({
        type: 'SELL_LOCATION',
        value: {
          ...lead,
          sellingLocation: {
            ...location
          }
        }
      });
      setLocation(location);
      setNextDisabled(false);
    } else {
      setNextDisabled(true);
    }
  };

  const previousScreen = () => {
    const value = location === lead?.sellingLocation?.selectedOption ? lead.sellingLocation : (location || {});
    if (context.flowOrder === 'Buying, Selling') {
      if (lead.operationType === 'BUYSELL') {
        navigate('/buy-location');
      } else if (lead.operationType === 'SELL') {
        navigate('/customer-info');
      }
    } else if (context.flowOrder === 'Selling, Buying') {
      if (lead.operationType === 'BUYSELL' || lead.operationType === 'SELL') {
        navigate('/customer-info');
      }
    } else if (context.flowOrder === 'Selling Only') {
      navigate('/customer-info');
    }

    // navigate('/buy-location');
  };

  const submitAnswer = () => {
    const value = location === lead?.sellingLocation?.selectedOption ? lead.sellingLocation : (location || {});
    dispatch({
      type: 'SELL_LOCATION',
      value: {
        ...lead,
        sellingLocation: {
          ...value,
          sellApt: apt
        }
      }
    });
    if (context.flowOrder === 'Buying, Selling') {
      if ((lead.operationType === 'BUYSELL' || lead.operationType === 'SELL') && compBasicModalLeadForm && compBasicModalLeadForm[0].additionalInfoScreen && compBasicModalLeadForm[0].additionalInfoScreen.length > 0) {
        navigate('/custom-question');
      } else if ((lead.operationType === 'BUYSELL' || lead.operationType === 'SELL') && compBasicModalLeadForm && compBasicModalLeadForm[0].displayResidenceLegalDisclaimerScreen && compBasicModalLeadForm[0].residenceLegalDisclaimerScreen) {
        navigate('/legal-residence');
      } else if ((lead.operationType === 'BUYSELL' || lead.operationType === 'SELL') && compBasicModalLeadForm && compBasicModalLeadForm[0].confirmationScreen) {
        navigate('/Confirmation-Screen');
      } else {
        navigate('/thankyou');
      }
    } else if (context.flowOrder === 'Selling, Buying') {
      if (lead.operationType === 'BUYSELL') {
        navigate('/buy-location');
      } else if (lead.operationType === 'SELL' && compBasicModalLeadForm && compBasicModalLeadForm[0].additionalInfoScreen && compBasicModalLeadForm[0].additionalInfoScreen.length > 0) {
        navigate('/custom-question');
      } else if (lead.operationType === 'SELL' && compBasicModalLeadForm && compBasicModalLeadForm[0].displayResidenceLegalDisclaimerScreen && compBasicModalLeadForm[0].residenceLegalDisclaimerScreen) {
        navigate('/legal-residence');
      } else if (lead.operationType === 'SELL' && compBasicModalLeadForm && compBasicModalLeadForm[0].confirmationScreen) {
        navigate('/Confirmation-Screen');
      } else {
        navigate('/thankyou');
      }
    } else if (context.flowOrder === 'Selling Only' && compBasicModalLeadForm && compBasicModalLeadForm[0].additionalInfoScreen && compBasicModalLeadForm[0].additionalInfoScreen.length > 0) {
      navigate('/custom-question');
    } else if (context.flowOrder === 'Selling Only' && compBasicModalLeadForm && compBasicModalLeadForm[0].displayResidenceLegalDisclaimerScreen && compBasicModalLeadForm[0].residenceLegalDisclaimerScreen) {
      navigate('/legal-residence');
    } else if (context.flowOrder === 'Selling Only' && compBasicModalLeadForm && compBasicModalLeadForm[0].confirmationScreen) {
      navigate('/Confirmation-Screen');
    } else {
      navigate('/thankyou');
    }

    // navigate('/custom-question');
  };

  const closeModal = () => {
    navigate('/');
    localStorage.removeItem('lead');
    localStorage.removeItem('sellApt');
  };

  return (
    lead && (
      <Container id="lead-form-sell-location" className={classes.root} >
        <Grid container>
          {/* <div className={classes.leadFormHelperBar}>
            <IconButton aria-label="close" className={classes.margin} onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </div> */}
          <Grid item xs={12} sm={12} md={12} lg={12} id="lead-form-sell-location-header">
            <Paper className={classes.paper}>
              <Typography id="lead-form-sell-location-header-h2" variant="h2" className={classes.internalContent}>{context.screenTitle}</Typography>
            </Paper>
          </Grid>
          <Grid container spacing={3} direction="row" alignItems="center" justifyContent="center">
            <Grid item xs={12} sm={12} md={12} lg={5} id="lead-form-sell-location-search">
              <Map fromSell showMap={false} place={setPlace} value={sellLocation || ''} required />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2} style={{ marginBottom: 'auto' }} className="whitebg" id="lead-form-sell-location-unit">
              <TextField
                id="lead-form-sell-location-apt"
                label="Apt/Unit #"
                onChange={onAptChange}
                value={apt}
                variant="outlined"
                className={classes.inputField}
                name="unitNum"
                inputProps={{ maxlength: 10 }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Paper className={clsx(classes.paper, classes.typoAlign)}>
                <Button id="lead-form-sell-location-prev-btn" onClick={previousScreen} size="large" className={clsx(globalThemeCss.outlinedBtn, classes.prevButton)} variant="outlined">{context.prevButtonLabel}</Button>
                <Button id="lead-form-sell-location-next-btn" onClick={submitAnswer} disabled={nextDisabled} size="large" className={globalThemeCss.containedBtn} variant="contained">{context.nextButtonLabel}</Button>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    )
  );
}
