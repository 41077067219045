/* eslint-disable func-names */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-unescaped-entities */
import React, { useContext, useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { Typography, Container } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
// import ToggleButton from '@material-ui/lab/ToggleButton';
// import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { navigate } from 'gatsby-link';
// import CloseIcon from '@material-ui/icons/Close';
// import IconButton from '@material-ui/core/IconButton';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
// import { INLINES } from '@contentful/rich-text-types';
import { LeadFormContextDispatch } from '../../utils/leadForm-context';
import { myStyles } from './styles';
import { useFormControls } from './customer-info-form-validation';
import { themeStyles } from '../../styles';
import '../../styles.scss';
import ThemeContext from '../../utils/theme-context';

// eslint-disable-next-line no-unused-vars
export default function CustomerInfo({ data, context }) {
  const classes = myStyles();
  // const NUMERIC_REGEX = /^[0-9-]+$/;
  const [phoneNumberValue, setphoneNumberValue] = useState('');

  const partnerTheme = useContext(ThemeContext);
  const globalThemeCss = themeStyles(partnerTheme);
  const [textMeVisible, setTextMeVisible] = useState(true);
  const {
    handleInputValue,
    formIsValid,
    errors,
    validate
  } = useFormControls();

  // eslint-disable-next-line no-unused-vars
  const dispatch = useContext(LeadFormContextDispatch);

  const [customerInfo, setcustomerInfo] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    phonetype: 'Mobile',
    textYou: false,
    memberNumber: ''
  });

  // const setToggle = (event, value) => {
  //   event.target.value = value;
  //   event.target.name = 'phonetype';
  //   setTextMeVisible(value === 'Mobile' ? true : false);
  //   setcustomerInfo({ ...customerInfo, phonetype: value });
  //   handleInputValue(event);
  // };

  useEffect(() => {
    if (performance.getEntriesByType('navigation')[0].type === 'navigate') {
      if (JSON.parse(localStorage.getItem('ast'))) {
        navigate('/ast');
      } else if (context.flowOrder === 'Selling, Buying' || context.flowOrder === 'Buying, Selling') {
        navigate('/service-selection');
      } else {
        window.location.reload();
      }
    }
    window.onbeforeunload = function () {
      localStorage.removeItem('lead');
      localStorage.removeItem('sellApt');
    };
    const getBuyLocation = JSON.parse(localStorage.getItem('buyLocation'));
    if (getBuyLocation?.length === 0) {
      localStorage.removeItem('buyLocation');
    }
    const getLead = JSON.parse(localStorage.getItem('lead'));
    setphoneNumberValue(getLead?.customerInfo?.phoneNumber);
    // setLead(getLead || {});
    // if (getLead?.customerInfo?.phonetype === 'Mobile') {
    //   setTextMeVisible(true);
    // }
    if (getLead?.customerInfo) {
      setcustomerInfo({ ...customerInfo, ...(getLead?.customerInfo || {}) });
      validate(getLead?.customerInfo);
    }
  }, []);

  // const handleAlignment = (event, newAlignment) => {
  //   setAlignment(newAlignment);
  // };

  const previousScreen = () => {
    const lead = JSON.parse(localStorage.getItem('lead'));
    dispatch({
      type: 'CUSTOMER_INFO',
      value: { ...lead, customerInfo }
    });
    navigate('/service-selection');
  };

  const saveCustomerInfo = () => {
    const lead = JSON.parse(localStorage.getItem('lead'));
    dispatch({
      type: 'CUSTOMER_INFO',
      value: { ...lead, customerInfo, memberNbrLabel: context.memberFieldLbl }
    });
  };

  const submitAnswer = () => {
    const lead = JSON.parse(localStorage.getItem('lead'));
    if (context.flowOrder === 'Buying Only') {
      dispatch({
        type: 'SERVICE_TYPE',
        value: { ...lead, operationType: 'BUY' }
      });
      saveCustomerInfo();
    } else if (context.flowOrder === 'Selling Only') {
      dispatch({
        type: 'SERVICE_TYPE',
        value: { ...lead, operationType: 'SELL' }
      });
      saveCustomerInfo();
    } else {
      saveCustomerInfo();
    }
    if (lead) {
      if (context.flowOrder === 'Buying, Selling') {
        if (lead?.operationType === 'BUYSELL') {
          navigate('/buy-location');
        } else if (lead.operationType === 'BUY') {
          navigate('/buy-location');
        } else if (lead.operationType === 'SELL') {
          navigate('/sell-location');
        }
      } else if (context.flowOrder === 'Selling, Buying') {
        if (lead?.operationType === 'BUYSELL') {
          navigate('/sell-location');
        } else if (lead.operationType === 'BUY') {
          navigate('/buy-location');
        } else if (lead.operationType === 'SELL') {
          navigate('/sell-location');
        }
      } else if (context.flowOrder === 'Buying Only') {
        navigate('/buy-location');
      } else if (context.flowOrder === 'Selling Only') {
        navigate('/sell-location');
      }
    }
    // navigate('/buy-location');
  };

  // const closeModal = () => {
  //   navigate('/');
  //   localStorage.removeItem('lead');
  // };

  function handlePhoneNumber(event) {
    const phoneValue = event.target.value.replace(/\D/g, '');

    setcustomerInfo(
      { ...customerInfo, phoneNumber: phoneValue }
    );
    setphoneNumberValue(phoneValue);
  }

  useEffect(() => {
    if (phoneNumberValue?.length === 10) {
      const cleaned = (`${phoneNumberValue}`).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        setphoneNumberValue(`(${match[1]})-${match[2]}-${match[3]}`);
      }
    }
  }, [phoneNumberValue]);

  return (
    <Container id="lead-form-customer-info" className={classes.root} >
      <Grid container className="whitebg">
        {/* <div className={classes.leadFormHelperBar}>
          <IconButton aria-label="close" className={classes.margin} onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </div> */}
        <Grid item xs={12} sm={12} md={12} lg={12} id="lead-form-customer-info-heading">
          <Paper className={classes.paper}>
            <Typography id="lead-form-customer-info-heading-h2" variant="h2" component="h2" className={classes.internalContent}>
              {context.screenTitle}
            </Typography>
            <Typography id="lead-form-customer-info-heading-para" className={classes.internalSubText}>
              {context.screenSubTitle}
            </Typography>
          </Paper>
        </Grid>
        <form className={classes.root} noValidate autoComplete="off">
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Paper className={classes.paper}>
                <TextField
                  id="lead-form-customer-info-fname"
                  label="First Name "
                  variant="outlined"
                  className={classes.inputField}
                  value={customerInfo.firstName}
                  required
                  name="firstName"
                  inputProps={{
                    maxlength: 50,
                    autocomplete: 'given-name'
                  }}
                  onKeyUp={handleInputValue}
                  onBlur={handleInputValue}
                  {...(errors.firstName && { error: true, helperText: errors.firstName })}
                  onChange={(event) => setcustomerInfo({ ...customerInfo, firstName: event.target.value })}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Paper className={classes.paper}>
                <TextField
                  id="lead-form-customer-info-lname"
                  label="Last Name "
                  variant="outlined"
                  value={customerInfo.lastName}
                  required
                  onKeyUp={handleInputValue}
                  onBlur={handleInputValue}
                  name="lastName"
                  inputProps={{
                    maxlength: 50,
                    autocomplete: 'family-name'
                  }}
                  {...(errors.lastName && { error: true, helperText: errors.lastName })}
                  onChange={(event) => setcustomerInfo({ ...customerInfo, lastName: event.target.value })}
                  className={classes.inputField}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Paper className={classes.paper}>
                <TextField
                  id="lead-form-customer-info-email"
                  label="Email "
                  variant="outlined"
                  inputProps={{ autocomplete: 'email' }}
                  value={customerInfo.email}
                  required
                  onKeyUp={handleInputValue}
                  onBlur={handleInputValue}
                  name="email"
                  {...(errors.email && { error: true, helperText: errors.email })}
                  onChange={(event) => setcustomerInfo({ ...customerInfo, email: event.target.value })}
                  className={classes.inputField}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Paper className={classes.paper}>
                <TextField
                  id="lead-form-customer-info-phone"
                  label="Phone Number "
                  variant="outlined"
                  value={(phoneNumberValue)}
                  required
                  onKeyUp={handleInputValue}
                  onBlur={handleInputValue}
                  name="phoneNumber"
                  InputProps={{ inputProps: { maxLength: 10, autocomplete: 'tel-national' } }}
                  {...(errors.phoneNumber && { error: true, helperText: errors.phoneNumber })}
                  // onChange={(event) => setcustomerInfo(
                  //   { ...customerInfo, phoneNumber: event.target.value }
                  // )}
                  onChange={(event) => {
                    handlePhoneNumber(event);
                  }}
                  className={classes.inputField}
                />
              </Paper>
            </Grid>
            {textMeVisible
              && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Paper className={classes.paper}>
                    <Typography className={classes.optText}>
                      <Checkbox
                        id="lead-form-customer-info-checkbox"
                        color="default"
                        checked={customerInfo.textYou}
                        onChange={() => setcustomerInfo({ ...customerInfo, textYou: !customerInfo.textYou })}
                      />
                      <label htmlFor="lead-form-customer-info-checkbox">
                        {context?.txtOptInDisclaimer?.raw
      && documentToReactComponents((JSON.parse(context?.txtOptInDisclaimer?.raw)))}
                      </label>
                    </Typography>
                  </Paper>
                </Grid>
              )}
            {context.isMemberField && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Paper className={classes.paper}>
                  <TextField
                    id="lead-form-customer-info-memberNumber"
                    label={context.memberFieldLbl}
                    variant="outlined"
                    value={customerInfo.memberNumber}
                    onKeyUp={handleInputValue}
                    name="memberNumber"
                    onChange={(event) => setcustomerInfo({ ...customerInfo, memberNumber: event.target.value })}
                    className={classes.inputField}
                  />
                </Paper>
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Paper className={clsx(classes.paper, classes.typoAlign)}>
                {(context.flowOrder === 'Buying, Selling' || context.flowOrder === 'Selling, Buying')
                  && (
                    <Button id="lead-form-customer-info-prev-btn" onClick={previousScreen} size="large" className={clsx(globalThemeCss.outlinedBtn, classes.prevButton)} variant="outlined">{context.prevButtonLabel}</Button>
                  )}
                <Button id="lead-form-customer-info-next-btn" onClick={submitAnswer} disabled={!formIsValid(customerInfo)} size="large" className={globalThemeCss.containedBtn} variant="contained">{context.nextButtonLabel}</Button>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Paper className={classes.paper}>
                <Typography id="lead-form-customer-info-disclaimer" className={classes.consentText}>
                  {context.legalDisclaimer?.raw && documentToReactComponents((JSON.parse(context.legalDisclaimer?.raw)))}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Container>
  );
}
