/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import { Paper, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { navigate } from 'gatsby-link';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { myStyles } from './styles';
import { LeadFormContextDispatch } from '../../utils/leadForm-context';
import ThemeContext from '../../utils/theme-context';
import { themeStyles } from '../../styles';

export default function ResidenceDisclaimer({ context }) {
  const classes = myStyles();
  const partnerTheme = useContext(ThemeContext);
  const globalThemeCss = themeStyles(partnerTheme);
  const [selectedState, setSelectedState] = React.useState('');
  const [nextDisabled, setNextDisabled] = useState(true);
  const compBasicModalLeadForm = useState(context.appData.compBasicModalLeadForm);
  const [lead, setLead] = useState('');
  const dispatch = useContext(LeadFormContextDispatch);
  useEffect(() => {
    if (performance.getEntriesByType('navigation')[0].type === 'navigate') {
      switch (context.flowOrder) {
        case 'Selling Only':
          navigate('/customer-info');
          break;
        case 'Buying Only':
          navigate('/customer-info');
          break;
        case 'Buying, Selling':
          navigate('/service-selection');
          break;
        case 'Selling, Buying':
          navigate('/service-selection');
          break;
        default:
          navigate('/');
      }
    }
    const getLead = JSON.parse(localStorage.getItem('lead'));
    setLead(getLead || {});
  }, []);

  useEffect(() => {
    if (lead.stateOfResd && selectedState === '') {
      setNextDisabled(false);
      setSelectedState(lead.stateOfResd);
    } else if (selectedState) {
      setNextDisabled(false);
    } else {
      setNextDisabled(true);
    }
  });
  const previousScreen = () => {
    if (context.flowOrder === 'Buying, Selling') {
      if ((lead.operationType === 'BUYSELL' || lead.operationType === 'BUY' || lead.operationType === 'SELL')
        && compBasicModalLeadForm && compBasicModalLeadForm[0].additionalInfoScreen
        && compBasicModalLeadForm[0].additionalInfoScreen.length > 0) {
        navigate('/custom-question');
      } else if (
        lead.operationType === 'BUYSELL' || lead.operationType === 'SELL') {
        navigate('/sell-location');
      } else if (lead.operationType === 'BUY') {
        navigate('/buy-location');
      }
    } else if (context.flowOrder === 'Selling, Buying') {
      if ((lead.operationType === 'BUYSELL' || lead.operationType === 'BUY' || lead.operationType === 'SELL')
      && compBasicModalLeadForm && compBasicModalLeadForm[0].additionalInfoScreen
      && compBasicModalLeadForm[0].additionalInfoScreen.length > 0) {
        navigate('/custom-question');
      } else if (lead.operationType === 'BUYSELL' || lead.operationType === 'BUY') {
        navigate('/buy-location');
      } else if (lead.operationType === 'SELL') {
        navigate('/sell-location');
      }
    } else if (context.flowOrder === 'Buying Only'){
      if(compBasicModalLeadForm && compBasicModalLeadForm[0].additionalInfoScreen && compBasicModalLeadForm[0].additionalInfoScreen.length > 0) {
      navigate('/custom-question');
      }
      else {
        navigate('/buy-location');
      }
    } else if (context.flowOrder === 'Selling Only'){
      if(compBasicModalLeadForm && compBasicModalLeadForm[0].additionalInfoScreen && compBasicModalLeadForm[0].additionalInfoScreen.length > 0) {
      navigate('/custom-question');
      } else {
        navigate('/sell-location');
      }
    }
  };
  const nextScreen = () => {
    dispatch({
      type: 'RESIDENCE_DISCLAIMER',
      value: { ...lead, stateOfResd: selectedState }
    });
    if (compBasicModalLeadForm && compBasicModalLeadForm[0].confirmationScreen) {
      navigate('/Confirmation-Screen');
    } else {
      navigate('/thankyou');
    }
  };
  const addSRMessage = (text) => {
    const tag = document.createElement('p');
    const textEle = document.createTextNode(text);
    tag.appendChild(textEle);
    const element = document.getElementById('sr-message');
    element.innerHTML = '';
    element.appendChild(tag);
  };
  const removeSRMessage = () => {
    const element = document.getElementById('sr-message');
    element.innerHTML = '';
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      updateSRMessage(selectedState);
    }, 500);

    return () => clearTimeout(timer);
  }, [selectedState]);

  const updateSRMessage = (selectedState) => {
    if(selectedState?.name === 'California'){
      const msg = documentToReactComponents(JSON.parse(context.legalText?.raw));
      let text = "";
      msg.forEach(element => {
        if (element.props && element.props.children) {
          element.props.children.forEach(child => {
            if (typeof child === "string") {
              text += child;
            } else if (child.props && child.props.children) {
              text += child.props.children;
            }
          });
        }
      });
      addSRMessage(text);
    }
    else{
      removeSRMessage();
    }
  };
  const stateList = [
    { name: 'Alabama', shortName: 'AL' },
    { name: 'Alaska', shortName: 'AK' },
    { name: 'Arizona', shortName: 'AZ' },
    { name: 'Arkansas', shortName: 'AR' },
    { name: 'California', shortName: 'CA' },
    { name: 'Colorado', shortName: 'CO' },
    { name: 'Connecticut', shortName: 'CT' },
    { name: 'Delaware', shortName: 'DE' },
    { name: 'District of Columbia', shortName: 'DC' },
    { name: 'Florida', shortName: 'FL' },
    { name: 'Georgia', shortName: 'GA' },
    { name: 'Hawaii', shortName: 'HI' },
    { name: 'Idaho', shortName: 'ID' },
    { name: 'Illinois', shortName: 'IL' },
    { name: 'Indiana', shortName: 'IN' },
    { name: 'Iowa', shortName: 'IA' },
    { name: 'Kansa', shortName: 'KS' },
    { name: 'Kentucky', shortName: 'KY' },
    { name: 'Lousiana', shortName: 'LA' },
    { name: 'Maine', shortName: 'ME' },
    { name: 'Maryland', shortName: 'MD' },
    { name: 'Massachusetts', shortName: 'MA' },
    { name: 'Michigan', shortName: 'MI' },
    { name: 'Minnesota', shortName: 'MN' },
    { name: 'Mississippi', shortName: 'MS' },
    { name: 'Missouri', shortName: 'MO' },
    { name: 'Montana', shortName: 'MT' },
    { name: 'Nebraska', shortName: 'NE' },
    { name: 'Nevada', shortName: 'NV' },
    { name: 'New Hampshire', shortName: 'NH' },
    { name: 'New Jersey', shortName: 'NJ' },
    { name: 'New Mexico', shortName: 'NM' },
    { name: 'New York', shortName: 'NY' },
    { name: 'North Carolina', shortName: 'NC' },
    { name: 'North Dakota', shortName: 'ND' },
    { name: 'Ohio', shortName: 'OH' },
    { name: 'Oklahoma', shortName: 'OK' },
    { name: 'Oregon', shortName: 'OR' },
    { name: 'Pennsylvania', shortName: 'PA' },
    { name: 'Rhode Island', shortName: 'RI' },
    { name: 'South Carolina', shortName: 'SC' },
    { name: 'South Dakota', shortName: 'SD' },
    { name: 'Tennessee', shortName: 'TN' },
    { name: 'Texas', shortName: 'TX' },
    { name: 'Utah', shortName: 'UT' },
    { name: 'Vermont', shortName: 'VT' },
    { name: 'Virginia', shortName: 'VA' },
    { name: 'Washington', shortName: 'WA' },
    { name: 'West Virginia', shortName: 'WV' },
    { name: 'Wisconsin', shortName: 'WI' },
    { name: 'Wyoming', shortName: 'WY' }
  ];
  return (
    <>
      {lead && (
        <Container id="lead-form-residence-legal-disclaimer">
          <Grid
            container
            direction="column"
            alignItems="initial"
            justify="center"
          >
            <Grid
              id="lead-form-residence-legal-disclsimer-header"
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <Typography
                id="lead-form-residence-legal-disclaimer-heading"
                variant="h2"
                className={classes.internalContent}
              >
                {context.titleHeading}
              </Typography>
              {context?.subTitle && (
                <Typography
                  id="lead-form-residence-legal-disclaimer-sub-heading"
                  className={classes.internalSubText}
                >
                  {context.subTitle}
                </Typography>
              )}
            </Grid>
            <Grid
              id="lead-form-residence-legal-disclaimer-select-state"
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ display: 'inline-grid', justifyItems: 'center'}}
            >
              <Autocomplete
                id="select-state-box"
                role="listbox"
                aria-describedby="sr-message"
                // aria-describedby="lead-form-residence-legal-disclaimer-legaltext"
                options={stateList}
                getOptionLabel={(option) => option.name}
                defaultValue={{ name: lead?.stateOfResd?.name }}
                style={{ width: 300, margin: '30px 0px 35px 0px' }}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    {...params}
                    label="Select State"
                  />
                )}
                selectedState={selectedState}
                onChange={(event, newValue) => {
                  setSelectedState(newValue);
                  updateSRMessage();
                }}
              />
              <div role="alert" id="sr-message"  className={classes.sronly} aria-live="polite" aria-atomic="true" />          
              {selectedState?.name === 'California' && (
                <Typography id="residence-legal-text"  variant="body2" className={classes.legalResidencePara}>
                  {documentToReactComponents(
                  JSON.parse(context.legalText?.raw)
                  )}
                </Typography>
              )}           
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Paper className={clsx(classes.paper, classes.typoAlign)}>
                <Button
                  id="lead-form-residence-disclaimer-prev-btn"
                  onClick={previousScreen}
                  size="large"
                  className={clsx(
                    globalThemeCss.outlinedBtn,
                    classes.prevButton
                  )}
                  variant="outlined"
                >
                  {context.prevButtonLabel}
                </Button>
                <Button
                  id="lead-form-residence-disclaimer-next-btn"
                  onClick={nextScreen}
                  size="large"
                  className={globalThemeCss.containedBtn}
                  variant="contained"
                  disabled={nextDisabled}
                >
                  {context.nextButtonLabel}
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
}
