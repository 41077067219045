/* eslint-disable max-len */
import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import { ThemeProvider } from '@material-ui/core/styles';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import LeadFormContextProvider from '../utils/leadForm-context';
import { myStyles, BorderLinearProgress } from '../components/signuppopup/styles';
import DynamicLeadForm from '../utils/dynamic-leadForm';
import ThemeContext from '../utils/theme-context';
import { customeTheme } from '../styles';
import CustomFonts from '../components/customFont/customfonts';
import Header from '../components/header';
import SEO from '../components/seo-component/seo';

export default function LeadFormPage({ pageContext, data, ...others }) {
  const classes = myStyles(pageContext);
  const navigation = pageContext?.appData?.navigation;
  const lastScreen = pageContext.lastScreen || false;
  const contactNumber = pageContext?.appData?.compBasicModalLeadForm?.callUs;
  const clientNumber = others?.pageResources?.json?.pageContext?.theme?.partnerMetaData?.clientNumber;
  const leadSource = others?.pageResources?.json?.pageContext?.theme?.partnerMetaData?.leadSource;
  const partnerId = others?.pageResources?.json?.pageContext?.appData?.partnerId;
  const [partnerTheme] = useState(pageContext.theme);
  const value = ((pageContext.pageIndex + 1) / 7) * 100;
  return (
    <>
      <a href="#main" className={classes.skipLink}>Skip to main content</a>
      {/* <div id="consent_blackbar" className="cookie-consent"> </div> */}
      <span id="consent_blackbar" className="cookie-consent"> </span>
      {/* <div id="main" className={classes.main} tabIndex="-1" role="main"> */}
      <CustomFonts data={pageContext?.theme?.typography} />
      <SEO
        title={pageContext.title}
      />
      <ThemeContext.Provider value={partnerTheme}>
        <ThemeProvider theme={customeTheme(partnerTheme)}>
          <Header contactNumber={contactNumber} navigation={navigation} isLeadForm="true" isLastScreen={lastScreen} />
          <main id="main" className={classes.main} tabIndex="-1">
            <Grid className={clsx(classes.root, classes.progressBar)}>
              <BorderLinearProgress aria-busy="false" variant="determinate" value={value} />
            </Grid>
            <Container style={{ marginTop: '110px', padding: '0' }}>
              <LeadFormContextProvider>
                {DynamicLeadForm({
                  screenType: pageContext.screenType, data, context: pageContext, partnerId, clientNumber, leadSource
                })}
              </LeadFormContextProvider>
            </Container>
          </main>
        </ThemeProvider>
      </ThemeContext.Provider>
      {/* </div> */}
    </>
  );
}
